@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');

.error {
    max-width: 100% ;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-bottom: 2rem;
}

.error__image { 
    width: 25rem;
    height: 14rem;
    margin-bottom: 1rem;
    background: url("../assets/error.png") no-repeat center center;
    background-size: cover;
}

.error__text {  
    font-family: 'Comfortaa', cursive;
    font-weight: 500;
    font-size: 1.1rem;
}