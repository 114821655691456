.field {
    background-color: #fff;
}

.field legend {
    width: 0;
}

.field .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.role {
    align-items: center;
    margin-right: 0.5rem;
    padding: 0 0.5rem 0.2rem 0.5rem;
    color: white;
    background-color: #1976d2;
    border-radius: 0.3rem;
}