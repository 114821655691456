.cursor-hover {
    cursor: pointer; 
}

.cursor_pointer {
    cursor: pointer; 
}

.table__th-width {
    width: 5%;
}

.table__td-width {
    width: 14rem;
    margin: 0;
    padding: 0;
}

.table__text-position {
    float: left;
}

.table__text-weight {
    font-weight: bold;
}

.table__text-green {
    color: #309752;
}

.table__text-red {
    color: #dc143c;
}

.table__statistics {
    background-color: #d6d8db;
    border: 2px solid #d6d8db
}

.table-pagination_select {
    min-width: 3rem;
}

.arrow {
    width: 0px;
    height: 0px;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    float: left;
}

.arrow .up {
    border: solid black; 
    border-width: 0 3px 3px 0px;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); 
}

.arrow .down {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}