.date_with {
    flex: 0 0 16.15rem;  
}

.groopings_width {
    flex: 0 0 25rem;  
}

.search_width {
    flex: 0 0 25rem;
}