@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap);
.authorization {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 25rem;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%)
}

.authorization__title {
    display: flex;
    justify-content: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.authorization__footer {
    display: flex;
    justify-content: space-between;
}

.reset-password__footer {
    display: flex;
    justify-content: flex-end;
}

.alert {
    position: absolute;
    top: 30rem;
    right: 25rem;
}

.sing-in__wrong-data {
    display: flex;
    justify-content: center;
    color: red;
    margin-top: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.sing-in__forgot-password {
    display: flex;
    margin: 0px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;    
    align-items: center;
    letter-spacing: 0.00938em;    
    color: rgb(25, 118, 210);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-text-decoration: underline rgba(25, 118, 210, 0.4);
            text-decoration: underline rgba(25, 118, 210, 0.4);
}

.sing-in__forgot-password:hover {
    -webkit-text-decoration-color: inherit;
            text-decoration-color: inherit;
}

.sing-in__forgot-password p {
    margin: 0px;
}
.cursor-hover {
    cursor: pointer; 
}

.cursor_pointer {
    cursor: pointer; 
}

.table__th-width {
    width: 5%;
}

.table__td-width {
    width: 14rem;
    margin: 0;
    padding: 0;
}

.table__text-position {
    float: left;
}

.table__text-weight {
    font-weight: bold;
}

.table__text-green {
    color: #309752;
}

.table__text-red {
    color: #dc143c;
}

.table__statistics {
    background-color: #d6d8db;
    border: 2px solid #d6d8db
}

.table-pagination_select {
    min-width: 3rem;
}

.arrow {
    width: 0px;
    height: 0px;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    float: left;
}

.arrow .up {
    border: solid black; 
    border-width: 0 3px 3px 0px;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); 
}

.arrow .down {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    color: white;
    text-decoration: none;
}

.link_disabled {
    pointer-events: none;
}
.modal_width { 
    width: 90%;
    max-width: 100%;
}

.campaign-modal_width { 
    width: 70%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}
.field {
    background-color: #fff;
}

.field legend {
    width: 0;
}

.field .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.role {
    align-items: center;
    margin-right: 0.5rem;
    padding: 0 0.5rem 0.2rem 0.5rem;
    color: white;
    background-color: #1976d2;
    border-radius: 0.3rem;
}
.calendar {
    position: absolute !important;
    z-index: 999;
    background-color: #fff;
    border: 1px solid rgba(104, 107, 110, 0.158);
}

.rdrMonth {
    width: 18rem !important;
    height: 18rem;
    padding: 0;
}

.rdrStaticRanges {
    height: 100%;
    justify-content: space-between !important;
}

.rdrStaticRange {
    width: 100%;
    height: 100%;
    text-align: center;
}

.rdrStaticRangeLabel {
    display: flex !important;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
}

.rdrMonthAndYearWrapper {
    max-height: 2rem;
    padding-top: 0.2rem !important;
}

.rdrDefinedRangesWrapper {
    width: 10rem !important;
}

.rdrInputRanges {
    display: none;
}

.date_with {
    flex: 0 0 16.15rem;  
}

.groopings_width {
    flex: 0 0 25rem;  
}

.search_width {
    flex: 0 0 25rem;
}
.error {
    max-width: 100% ;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-bottom: 2rem;
}

.error__image { 
    width: 25rem;
    height: 14rem;
    margin-bottom: 1rem;
    background: url(/spa/static/media/error.4457f80c.png) no-repeat center center;
    background-size: cover;
}

.error__text {  
    font-family: 'Comfortaa', cursive;
    font-weight: 500;
    font-size: 1.1rem;
}
.modal-width { 
    width: 90%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.postback-info {
    border: 2px solid;
    border-radius: 10px !important;
    border-color: #3f50b5;
}

.postback-info__title {
    color: #3f50b5
}

.postback-info .MuiAccordionSummary-expandIconWrapper {
    color: #3f50b5 !important;
}

.postback-url .MuiOutlinedInput-root {
    padding: 0;
    padding-bottom: 0.438rem;
}

.postback-active {
    width: 60%;
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    white-space: nowrap;
    max-height: 2.4rem;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.postback-active_succes {
    color: green;
    border-color:green;
}

.postback-active_succes:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.postback-active_error {
    color: red;
    border-color:red;
}
.modal_width { 
    width: 90%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.user {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    padding: 0 0.5rem 0.2rem 0.5rem;
    border-radius: 0.3rem;
}

.user_color {
    color: white;
    background-color: #1976d2;
}
.filter {
    max-width: 29.16rem;
}

.filter-item_select {
    color:black
}

.filter-item_select:focus {
    background-color: rgba(25, 118, 210, 0.1) !important;
}

.filter-item_select:hover {
    background-color: rgba(25, 118, 210, 0.12) !important;
}

.groopings {
    min-width: 22rem;
}
.table__campaign {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: white;
    background-color: #c27ba0;
    border-radius: 0.3rem;
}

.table__fullname {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;    
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: black;
    background-color: rgba(0,0,0,.075);
    border-radius: 0.3rem;
}

.table__source {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;    
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: black;
    background-color: #ffe494;
    border-radius: 0.3rem;
}
.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.modal__container-width {
    max-width: 50%;
}

.modal__band {
    border-right: 1px solid #949494; 
}

.modal__tooltip {
    position: absolute;
    top: 1.8rem;
    left: -1.8rem; 
}

.postback-limit-type {
    width: 100%;
    max-width: 19.6rem;
}

.limit-validation {
    width: 100%;
    color: #d32f2f;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    border-top: 1px solid #d32f2f; 
}

.shedule__tooltip {
    color: rgba(0, 0, 0, 0.30);
}

.shedule__tooltip-text {
    margin: 0;
    font-size: 0.9rem;
}

.shedule__group {
    width: 21rem;
    max-width: 100% ;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
}

.shedule__group .shedule__hour {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;   
    margin: 0.1rem !important;
    border: 1px solid transparent !important;
    border-radius: 10% !important;
    background-color: rgba(0, 0, 0, 0.12);
}

.shedule__group .Mui-selected {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;   
    margin: 0.1rem !important;
    border: 1px solid transparent !important;
    border-radius: 10% !important;
    color: white !important;
    background-color: #309752 !important;
}

.shedule__group .shedule__hour:hover:after { 
    z-index: 1;
    content:""; 
    display: block;
    position: absolute; 
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10%;
    background-color:grey !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QQVDREwZcKhEwAAAPNJREFUSMftk88KQUEUxu9jyDNcS+IhbDyMlTfwBlZ3xeIubawkS0nZSFKTomQjlrrf/CwmIX8Sc7Pg25ya5ny/70xnguCvlIXFYsMQVFNtOAR11FksIDGJqVRSBudyoJFGmw1IElzUgQbr9TfAp9pQYzL5BtjI7HbufqEQAECpBLZpm90u2KqttlruPJPxDgagWDwboLbaxtxpiBRNp64hm70PDsO3wRcBeuotl08MroJ4A58nSeIkLpdBe+0PhyeGffVXK9BMs+32Y/DtS7h/+UIQv2APQfyA3wiSDvjhjljFiudzUF318dgtYz6fGvhndARGhcuTWZ6S3QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0yMVQxMzoxNzo0OCswMDowMDy6BxoAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDQtMjFUMTM6MTc6NDgrMDA6MDBN57+mAAAAAElFTkSuQmCC) no-repeat center center; 
}

.shedule__group .Mui-selected:hover:after {
    z-index: 1; 
    content:"";
    display: block;
    position: absolute; 
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10%; 
    background-color: #309752 !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAQAAADlauupAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQflBBUNDyXcXnonAAAAsklEQVQ4y6WTXRKDIAyEsQcSZvQyeGvwFhL1Bnx9sDpFUazNE0Oym82fgq6DcQTviVqrghG1Bu9BBKxVy2M1ETDmFIwxafwwqIXt2/IkRzCAcx9Je0dKkgeLbCUTtSaGkAZME7Ftr3w3JIqU1B07fMi2y0zTlMeUJcmDX6W5p+xV9TD7RfPSDXvYxNNR0TR53zxvSoh1/dciQd8/X2XvVSrvx2OKISiwdvl07v45O7ee8xvKOVI1DJSjoAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNC0yMVQxMzoxNTozNyswMDowMMTCrtcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDQtMjFUMTM6MTU6MzcrMDA6MDC1nxZrAAAAAElFTkSuQmCC) no-repeat center center; 
}

#square-green {
    position: relative;
    top: 7px;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0.1rem;
    border-radius: 20%;
	background: #309752;
}

#square-grey {
    position: relative;
    top: 7px;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0.1rem;
    border-radius: 20%;
	background: rgba(0, 0, 0, 0.12);
}

.goal-enable {
    white-space: nowrap;
}

.rules__tooltip {
    position: absolute;
    top: 0.5rem;
    left: 14rem; 
}

.condition__tooltip {
    position: absolute;
    top: 0.5rem;
    left: -1.8rem;
}

.table__type {
    min-width: 10rem;
}

.table__field {
    min-width: 15rem;
}

.source-id .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.rule-offer .MuiIcon .MuiAutocomplete-popupIndicator{
    margin-right: 2rem !important;
}

.rule-offer .MuiAutocomplete-inputRoot  {
    padding: 0 !important;
}

.rule-offer .MuiOutlinedInput-root {
    padding: 0 !important;
    padding-right: 0.5rem !important;
}

.url__field .MuiInputLabel-formControl {
    -webkit-transform: translate(0, 10px) scale(1);
            transform: translate(0, 10px) scale(1);
}

.url__field label + .MuiInput-formControl {
    margin: 0;
    min-height: 35px;
}

.url__field .MuiInputLabel-shrink {
    -webkit-transform: translate(1px, -12px) scale(0.75);
            transform: translate(1px, -12px) scale(0.75)
}

.url__field .MuiInputBase-multiline {
    padding: 0;
}

.rule-percent {
    min-width: 7.9rem;
}

.rule-delete-button {
    width: 10rem;
}

.lending__select {
    display: flex;
    background-color: #fff;
}

.lending__select legend {
    width: 0;
}

.lending__select .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.lending__input {
    margin-left: 2rem;
    width: 100%;
}

.payment-model-radio {
    min-width: 15rem;
}

.payment-model-value {
    min-width: 15rem;
}

.payout-model-info {
    border: 2px solid;
    border-radius: 10px !important;
    border-color: #3f50b5;
}

.payout-model-info__title {
    color: #3f50b5
}

.payout-model-info .MuiAccordionSummary-expandIconWrapper {
    color: #3f50b5 !important;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: 1200px;
}

.layout-width {
    max-width: 99%;
}

.MuiOutlinedInput-input {
    padding: 0.5rem !important;
}

button:focus {
    outline: none; 
}

.pointer-events {
    pointer-events: none;
}

.pointer-events .MuiPaper-root {
    background-color: #1976d2;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: -0.5em;
    min-height: 2em;
    background-color:#f8f9fa;
    z-index: 999;
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.user__dropdown_width {
    min-width: 16rem;
}

.nav-link {
    position: relative;
    padding: 0;
}

.nav-link > p {
    color: rgba(0,0,0,.5);
    padding: 0 .8rem;
    margin: 0;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
}

.nav-link:hover > p {
    color: rgba(0,0,0,.9);
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.dropdown {
    position: absolute;
    z-index: 1000;
    top: 50%;
    right: -1rem;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .5s;
    min-width: 13.5rem;
    max-width: 14rem;
    padding: .5rem 0;
    margin: 0.8rem 0 0 -7rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

.dropdown ul {
    position: relative;
    list-style-type: none; 
    padding: 0;
    margin: 0;
}

.dropdown-link > a, .dropdown-link > div {
    display: flex;
    color: rgba(0,0,0,.5);
    padding: .5rem 1rem;
    font-size: 1rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
    text-decoration: none;
}

.dropdown-link:hover > a, .dropdown-link:hover > div {
    background-color: rgba(0,0,0,.2);
    color: rgba(0,0,0,.9);
    cursor: pointer;
}

.nav-link:hover > .dropdown,
.dropdown-link:hover > .dropdown {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}
