body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: 1200px;
}

.layout-width {
    max-width: 99%;
}

.MuiOutlinedInput-input {
    padding: 0.5rem !important;
}

button:focus {
    outline: none; 
}

.pointer-events {
    pointer-events: none;
}

.pointer-events .MuiPaper-root {
    background-color: #1976d2;
}

.sticky {
    position: sticky;
    top: -0.5em;
    min-height: 2em;
    background-color:#f8f9fa;
    z-index: 999;
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.user__dropdown_width {
    min-width: 16rem;
}

.nav-link {
    position: relative;
    padding: 0;
}

.nav-link > p {
    color: rgba(0,0,0,.5);
    padding: 0 .8rem;
    margin: 0;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
}

.nav-link:hover > p {
    color: rgba(0,0,0,.9);
    cursor: pointer;
    transform: scale(1.1);
}

.dropdown {
    position: absolute;
    z-index: 1000;
    top: 50%;
    right: -1rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .5s;
    min-width: 13.5rem;
    max-width: 14rem;
    padding: .5rem 0;
    margin: 0.8rem 0 0 -7rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

.dropdown ul {
    position: relative;
    list-style-type: none; 
    padding: 0;
    margin: 0;
}

.dropdown-link > a, .dropdown-link > div {
    display: flex;
    color: rgba(0,0,0,.5);
    padding: .5rem 1rem;
    font-size: 1rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
    text-decoration: none;
}

.dropdown-link:hover > a, .dropdown-link:hover > div {
    background-color: rgba(0,0,0,.2);
    color: rgba(0,0,0,.9);
    cursor: pointer;
}

.nav-link:hover > .dropdown,
.dropdown-link:hover > .dropdown {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}