.modal-width { 
    width: 90%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.postback-info {
    border: 2px solid;
    border-radius: 10px !important;
    border-color: #3f50b5;
}

.postback-info__title {
    color: #3f50b5
}

.postback-info .MuiAccordionSummary-expandIconWrapper {
    color: #3f50b5 !important;
}

.postback-url .MuiOutlinedInput-root {
    padding: 0;
    padding-bottom: 0.438rem;
}

.postback-active {
    width: 60%;
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    white-space: nowrap;
    max-height: 2.4rem;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.postback-active_succes {
    color: green;
    border-color:green;
}

.postback-active_succes:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.postback-active_error {
    color: red;
    border-color:red;
}