.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    color: white;
    text-decoration: none;
}

.link_disabled {
    pointer-events: none;
}