.modal_width { 
    width: 90%;
    max-width: 100%;
}

.campaign-modal_width { 
    width: 70%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}