.calendar {
    position: absolute !important;
    z-index: 999;
    background-color: #fff;
    border: 1px solid rgba(104, 107, 110, 0.158);
}

.rdrMonth {
    width: 18rem !important;
    height: 18rem;
    padding: 0;
}

.rdrStaticRanges {
    height: 100%;
    justify-content: space-between !important;
}

.rdrStaticRange {
    width: 100%;
    height: 100%;
    text-align: center;
}

.rdrStaticRangeLabel {
    display: flex !important;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
}

.rdrMonthAndYearWrapper {
    max-height: 2rem;
    padding-top: 0.2rem !important;
}

.rdrDefinedRangesWrapper {
    width: 10rem !important;
}

.rdrInputRanges {
    display: none;
}
