.table__campaign {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: white;
    background-color: #c27ba0;
    border-radius: 0.3rem;
}

.table__fullname {
    display: flex;
    align-items: center;
    width: fit-content;    
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: black;
    background-color: rgba(0,0,0,.075);
    border-radius: 0.3rem;
}

.table__source {
    display: flex;
    align-items: center;
    width: fit-content;    
    margin-bottom: 0.2rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: black;
    background-color: #ffe494;
    border-radius: 0.3rem;
}