.modal_width { 
    width: 90%;
    max-width: 100%;
}

.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.user {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    padding: 0 0.5rem 0.2rem 0.5rem;
    border-radius: 0.3rem;
}

.user_color {
    color: white;
    background-color: #1976d2;
}