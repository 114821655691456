.modal-overflow {
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: scroll;
}

.modal__container-width {
    max-width: 50%;
}

.modal__band {
    border-right: 1px solid #949494; 
}

.modal__tooltip {
    position: absolute;
    top: 1.8rem;
    left: -1.8rem; 
}

.postback-limit-type {
    width: 100%;
    max-width: 19.6rem;
}

.limit-validation {
    width: 100%;
    color: #d32f2f;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    border-top: 1px solid #d32f2f; 
}

.shedule__tooltip {
    color: rgba(0, 0, 0, 0.30);
}

.shedule__tooltip-text {
    margin: 0;
    font-size: 0.9rem;
}

.shedule__group {
    width: 21rem;
    max-width: 100% ;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
}

.shedule__group .shedule__hour {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;   
    margin: 0.1rem !important;
    border: 1px solid transparent !important;
    border-radius: 10% !important;
    background-color: rgba(0, 0, 0, 0.12);
}

.shedule__group .Mui-selected {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;   
    margin: 0.1rem !important;
    border: 1px solid transparent !important;
    border-radius: 10% !important;
    color: white !important;
    background-color: #309752 !important;
}

.shedule__group .shedule__hour:hover:after { 
    z-index: 1;
    content:""; 
    display: block;
    position: absolute; 
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10%;
    background-color:grey !important;
    background: url("../assets/done.png") no-repeat center center; 
}

.shedule__group .Mui-selected:hover:after {
    z-index: 1; 
    content:"";
    display: block;
    position: absolute; 
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10%; 
    background-color: #309752 !important;
    background: url("../assets/delete.png") no-repeat center center; 
}

#square-green {
    position: relative;
    top: 7px;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0.1rem;
    border-radius: 20%;
	background: #309752;
}

#square-grey {
    position: relative;
    top: 7px;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0.1rem;
    border-radius: 20%;
	background: rgba(0, 0, 0, 0.12);
}

.goal-enable {
    white-space: nowrap;
}

.rules__tooltip {
    position: absolute;
    top: 0.5rem;
    left: 14rem; 
}

.condition__tooltip {
    position: absolute;
    top: 0.5rem;
    left: -1.8rem;
}

.table__type {
    min-width: 10rem;
}

.table__field {
    min-width: 15rem;
}

.source-id .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.rule-offer .MuiIcon .MuiAutocomplete-popupIndicator{
    margin-right: 2rem !important;
}

.rule-offer .MuiAutocomplete-inputRoot  {
    padding: 0 !important;
}

.rule-offer .MuiOutlinedInput-root {
    padding: 0 !important;
    padding-right: 0.5rem !important;
}

.url__field .MuiInputLabel-formControl {
    transform: translate(0, 10px) scale(1);
}

.url__field label + .MuiInput-formControl {
    margin: 0;
    min-height: 35px;
}

.url__field .MuiInputLabel-shrink {
    transform: translate(1px, -12px) scale(0.75)
}

.url__field .MuiInputBase-multiline {
    padding: 0;
}

.rule-percent {
    min-width: 7.9rem;
}

.rule-delete-button {
    width: 10rem;
}

.lending__select {
    display: flex;
    background-color: #fff;
}

.lending__select legend {
    width: 0;
}

.lending__select .MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.lending__input {
    margin-left: 2rem;
    width: 100%;
}

.payment-model-radio {
    min-width: 15rem;
}

.payment-model-value {
    min-width: 15rem;
}

.payout-model-info {
    border: 2px solid;
    border-radius: 10px !important;
    border-color: #3f50b5;
}

.payout-model-info__title {
    color: #3f50b5
}

.payout-model-info .MuiAccordionSummary-expandIconWrapper {
    color: #3f50b5 !important;
}