.filter {
    max-width: 29.16rem;
}

.filter-item_select {
    color:black
}

.filter-item_select:focus {
    background-color: rgba(25, 118, 210, 0.1) !important;
}

.filter-item_select:hover {
    background-color: rgba(25, 118, 210, 0.12) !important;
}
