.authorization {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 25rem;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.authorization__title {
    display: flex;
    justify-content: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.authorization__footer {
    display: flex;
    justify-content: space-between;
}

.reset-password__footer {
    display: flex;
    justify-content: flex-end;
}

.alert {
    position: absolute;
    top: 30rem;
    right: 25rem;
}

.sing-in__wrong-data {
    display: flex;
    justify-content: center;
    color: red;
    margin-top: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.sing-in__forgot-password {
    display: flex;
    margin: 0px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;    
    align-items: center;
    letter-spacing: 0.00938em;    
    color: rgb(25, 118, 210);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-decoration: underline rgba(25, 118, 210, 0.4);
}

.sing-in__forgot-password:hover {
    text-decoration-color: inherit;
}

.sing-in__forgot-password p {
    margin: 0px;
}